<script>
import TablePage from '@/found/components/table_page';
import Form from '../form/form.vue';

export default {
  extends: TablePage,
  components: {
    // Modal,
    Form,
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('capital-pool-cash');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'adjustment') {
        this.modalConfig.title = '手动';
        this.formConfig.poolId = row.id;
        this.formConfig.code = 'edit';
        this.openModal();
      }
      if (val.code === 'detail') {
        this.$router.push(`/capital-pool/cash/detail?poolId=${row.id}`);
      }
    },
  },
};
</script>
